import {EliteConfig, Language} from "./utils";

export class LocalTime {
  readonly timeString: string
  readonly hour: number
  readonly minute: number
  readonly second: number

  // Format: hh:mm
  constructor(timeString: string) {
    console.assert(timeString.length >= 5)
    console.assert(timeString.length <= 8)

    let parts = timeString.split(":")

    this.hour = parseInt(parts[0], 10)
    this.minute = parts.length > 1 ? parseInt(parts[1], 10) : 0
    this.second = parts.length > 2 ? parseInt(parts[1], 10) : 0
    this.timeString = timeString;

    console.assert(this.second >= 0 && this.second < 60)
    console.assert(this.minute >= 0 && this.minute < 60)
    console.assert(this.hour >= 0 && this.hour < 24)
  }

  toString() {
    return this.timeString
  }

  toLocalSeconds(): number {
    return this.second + this.minute * 60 + this.hour * 3600
  }

  toLocalMinutes(): number {
    return this.minute + this.hour * 60
  }

  isBefore(other: LocalTime) {
    return this.toLocalSeconds() < other.toLocalSeconds()
  }

  isBeforeOrEqual(other: LocalTime) {
    return this.toLocalSeconds() <= other.toLocalSeconds()
  }

  isAfter(other: LocalTime) {
    return this.toLocalSeconds() > other.toLocalSeconds()
  }

  isAfterOrEqual(other: LocalTime) {
    return this.toLocalSeconds() >= other.toLocalSeconds()
  }

  isEqual(other: LocalTime) {
    return this.toLocalSeconds() == other.toLocalSeconds()
  }


  diffString(other: LocalTime): string {
    let diff = this.diffMinutes(other)

    return LocalTime.minutesToString(diff);
  }

  diffMinutes(other: LocalTime) {
    return Math.abs(this.toLocalMinutes() - other.toLocalMinutes());
  }

  diffSeconds(other: LocalTime) {
    return Math.abs(this.toLocalSeconds() - other.toLocalSeconds());
  }

  combineWithDate(datePart: Date): Date {
    var dt = new Date(datePart.getTime());

    dt.setHours(this.hour, this.minute, this.second, 0)

    return dt;
  }

  static minutesToString(minutes: number, whenEmpty: string = "-") {
    let str = ""
    let hoursSuffix = EliteConfig.language == Language.NORWEGIAN ? "t" : "h"
    let minutesSuffix = "m"

    if (minutes >= 60) {
      str += Math.floor((minutes / 60)) + hoursSuffix + " "
      minutes %= 60
    }

    if (minutes > 0)
      str += minutes + minutesSuffix

    if (str.length == 0)
      str = whenEmpty

    return str
  }

  static from(obj: any): LocalTime {
    return obj == null ? null : new LocalTime(obj.timeString)
  }
}

export class TimeRange {
  startTime: LocalTime
  endTime: LocalTime

  minutes() {
    return (this.startTime && this.endTime) ? this.startTime.diffMinutes(this.endTime) : 0
  }

  seconds() {
    return (this.startTime && this.endTime) ? this.startTime.diffSeconds(this.endTime) : 0
  }

  diffString(): string {
    return LocalTime.minutesToString(this.minutes());
  }

  clone(): TimeRange {
    let newRange = new TimeRange()

    newRange.startTime = this.startTime
    newRange.endTime = this.endTime

    return newRange
  }

  static from(obj: any): TimeRange {
    if (obj == null)
      return null

    let timeRange = new TimeRange()

    timeRange.startTime = LocalTime.from(obj.startTime)
    timeRange.endTime = LocalTime.from(obj.endTime)

    return timeRange
  }

  toString(separator: string = "-"): string {
    return this.startTime + separator + this.endTime
  }
}

export class DateRange {
  readonly startDate: Date
  readonly endDate: Date

  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  inRange(date: Date): boolean {
    let epoc = date.getTime()

    return epoc >= this.startDate.getTime() && epoc < this.endDate.getTime()
  }

  static onlyPastMonths(numMonths: number): DateRange {
    let now = new Date()
    let dateStart = new Date(now.getFullYear(), now.getMonth() - numMonths)
    let dateEnd = new Date(now.getFullYear(), now.getMonth() + 1)

    return new DateRange(dateStart, dateEnd)
  }

  static onlyFutureMonths(numMonths: number): DateRange {
    let now = new Date()
    let dateStart = new Date(now.getFullYear(), now.getMonth())
    let dateEnd = new Date(now.getFullYear(), now.getMonth() + numMonths)

    return new DateRange(dateStart, dateEnd)
  }

  static pastAndFutureMonths(numMonthsPast: number, numMonthsFuture: number): DateRange {
    let now = new Date()
    let dateStart = new Date(now.getFullYear(), now.getMonth() - numMonthsPast)
    let dateEnd = new Date(now.getFullYear(), now.getMonth() + numMonthsFuture + 1)

    return new DateRange(dateStart, dateEnd)
  }

  static addMonths(date: Date, numMonths: number): Date {
    return new Date(date.getFullYear(), date.getMonth() + numMonths, date.getDate())
  }
}

export enum WeekCoverage {
  WEEKDAYS,
  WEEKDAYS_AND_SATURDAY,
  ALL_DAYS
}

export enum Month {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11
}

export enum Day {
  SUNDAY = 0, MONDAY = 1, TUESDAY = 2, WEDNESDAY = 3, THURSDAY = 4, FRIDAY = 5, SATURDAY = 6
}

export function monthFrom1(month: number): Month {
  console.assert(month >= 1 && month <= 12)

  return monthFrom0(month - 1)
}

export function monthFrom0(month: number): Month {
  console.assert(month >= 0 && month <= 11)

  switch (month) {
    case 0:
      return Month.JANUARY;
    case 1:
      return Month.FEBRUARY;
    case 2:
      return Month.MARCH;
    case 3:
      return Month.APRIL;
    case 4:
      return Month.MAY;
    case 5:
      return Month.JUNE;
    case 6:
      return Month.JULY;
    case 7:
      return Month.AUGUST;
    case 8:
      return Month.SEPTEMBER;
    case 9:
      return Month.OCTOBER;
    case 10:
      return Month.NOVEMBER;
    case 11:
      return Month.DECEMBER;
    default:
      return null
  }
}

export function dayNameFromNumber(day: number, maxLength = 100) {
  return dayName(dayFrom0(day), maxLength)
}

export function dayName(day: Day, maxLength = 100) {
  if(day === null)
    return null

  let name = fullDayName(day)

  if (name.length > maxLength)
    return name.substring(0, maxLength)

  return name
}

export function fullDayName(day: Day) {
  if (EliteConfig.language == Language.NORWEGIAN) {
    switch (day) {
      case Day.SUNDAY:
        return "Søndag"
      case Day.MONDAY:
        return "Mandag"
      case Day.TUESDAY:
        return "Tirsdag"
      case Day.WEDNESDAY:
        return "Onsdag"
      case Day.THURSDAY:
        return "Torsdag"
      case Day.FRIDAY:
        return "Fredag"
      case Day.SATURDAY:
        return "Lørdag"
    }
  }

  switch (day) {
    case Day.SUNDAY:
      return "Sunday"
    case Day.MONDAY:
      return "Monday"
    case Day.TUESDAY:
      return "Tuesday"
    case Day.WEDNESDAY:
      return "Wednesday"
    case Day.THURSDAY:
      return "Thursday"
    case Day.FRIDAY:
      return "Friday"
    case Day.SATURDAY:
      return "Saturday"
  }
}

export function monthNameFromNumber(month: number, maxLength = 100) {
  return monthName(monthFrom0(month), maxLength)
}

export function monthName(month: Month, maxLength = 100) {
  let name = fullMonthName(month)

  if (name.length > maxLength)
    return name.substring(0, maxLength)

  return name
}

export function fullMonthName(month: Month) {
  if (EliteConfig.language == Language.NORWEGIAN) {
    switch (month) {
      case Month.JANUARY:
        return "Januar"
      case Month.FEBRUARY:
        return "Februar"
      case Month.MARCH:
        return "Mars"
      case Month.APRIL:
        return "April"
      case Month.MAY:
        return "Mai"
      case Month.JUNE:
        return "Juni"
      case Month.JULY:
        return "Juli"
      case Month.AUGUST:
        return "August"
      case Month.SEPTEMBER:
        return "September"
      case Month.OCTOBER:
        return "Oktober"
      case Month.NOVEMBER:
        return "November"
      case Month.DECEMBER:
        return "Desember"
    }
  }

  switch (month) {
    case Month.JANUARY:
      return "January"
    case Month.FEBRUARY:
      return "February"
    case Month.MARCH:
      return "March"
    case Month.APRIL:
      return "April"
    case Month.MAY:
      return "May"
    case Month.JUNE:
      return "June"
    case Month.JULY:
      return "July"
    case Month.AUGUST:
      return "August"
    case Month.SEPTEMBER:
      return "September"
    case Month.OCTOBER:
      return "October"
    case Month.NOVEMBER:
      return "November"
    case Month.DECEMBER:
      return "December"
  }
}

export function dayFrom0(day: number): Day {
  if (day === null)
    return null
  console.assert(day >= 0 && day <= 6)

  switch (day) {
    case 0:
      return Day.SUNDAY;
    case 1:
      return Day.MONDAY;
    case 2:
      return Day.TUESDAY;
    case 3:
      return Day.WEDNESDAY;
    case 4:
      return Day.THURSDAY;
    case 5:
      return Day.FRIDAY;
    case 6:
      return Day.SATURDAY;
    default:
      return null
  }
}

export function monthToOneIndex(month: Month): number {
  return monthToZeroIndex(month) + 1
}

export function monthToZeroIndex(month: Month): number {
  switch (month) {
    case Month.JANUARY:
      return 0;
    case Month.FEBRUARY:
      return 1;
    case Month.MARCH:
      return 2;
    case Month.APRIL:
      return 3;
    case Month.MAY:
      return 4;
    case Month.JUNE:
      return 5;
    case Month.JULY:
      return 6;
    case Month.AUGUST:
      return 7;
    case Month.SEPTEMBER:
      return 8;
    case Month.OCTOBER:
      return 9;
    case Month.NOVEMBER:
      return 10;
    case Month.DECEMBER:
      return 11;
    default:
      return null
  }
}
